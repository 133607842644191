<template>
  <el-container>
    <el-header>
      <el-row type="flex" justify="start">
        <el-col :span="3">
        </el-col>
        <el-col :span="18" style="display: flex; justify-content: center;">
          <div style="display: flex; align-items: center; color: white; font-weight: 900;">
            <img src="/img/titlelogo.png" style="height: 1em; margin-right: 0.2em;"/> 诺比单车分析器
          </div>
        </el-col>
        <el-col :span="1">
          <el-link type="info" :underline="false">
            <i class="el-icon-fork-spoon" @click="centerDialogVisible = true"></i>
          </el-link>
        </el-col>
        <el-col :span="1">
          <el-link type="info" :underline="false">
            <i class="el-icon-full-screen" v-if="!isMobile" @click="toggleFullScreen"></i>
          </el-link>
        </el-col>
        <el-col :span="1">
          <el-link type="info" :underline="false"><i class="el-icon-menu" @click="toggleDrawer"></i></el-link>
        </el-col>
      </el-row>
    </el-header>
    <el-drawer :show-close="true" :with-header="true" title="更多说明" :visible.sync="drawer" direction="rtl"
               :size="drawerWidth">
      <div v-html="html" class="text-div" style="padding:20px"></div>
    </el-drawer>
    <el-dialog

        :visible.sync="centerDialogVisible"
        :width="dialogWidth"
        center>
      <div style="text-align: center">
<pre>
妙手回春
                     ———— 诺比
齿比错乱心烦恼，赏赐作者校得好。
滚动系数显太高，馈赠作者帮我削。
坡度骇人阻前路，犒赏作者把峰除。
风阻曲线太陡峭，酬谢作者后台调。
</pre>
      </div>


      <img src="/img/wechatpay.png" width='100%' style="text-align: center"/>
    </el-dialog>
    <el-main id="main">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
          <div class="grid-content input-div">
            <div class="title2">数据输入</div>
            <div id="formContainer">
              <el-form label-width="auto" size="mini" :inline="false" label-position="left" :rules="rules" :model="form"
                       ref="inputForm" @keyup.enter.native="onSubmit">
                <el-form-item label="牙盘规格" prop="driveDiskArrStr">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-select v-model="form.driveDiskArrStr" filterable allow-create placeholder="请选择牙盘规格">
                      <el-option-group v-for="group in driveDiskOptions" :key="group.label" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-option-group>
                    </el-select>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[0]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>

                <el-form-item label="飞轮规格" prop="flywheelArrStr">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-select v-model="form.flywheelArrStr" filterable allow-create placeholder="请选择飞轮规格">
                      <el-option-group v-for="group in flywheelOptions" :key="group.label" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.label" :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-option-group>
                    </el-select>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[1]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>

                </el-form-item>
                <el-form-item label="轮组规格" prop="wheel">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-select v-model="form.wheel" filterable allow-create placeholder="请选择轮组规格">
                      <el-option-group v-for="group in wheelOptions" :key="group.label" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.label" :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-option-group>
                    </el-select>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[2]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>

                <el-form-item label="常用踏频" prop="stepFrequency">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-select v-model="form.stepFrequency" placeholder="请选择常用踏频">
                      <el-option v-for="item in stepFrequencyOptions" :key="item.label" :label="item.label"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[3]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>

                <el-form-item label="人车质量" prop="mass">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-select v-model="form.mass" filterable allow-create placeholder="请选择人车质量">
                      <el-option v-for="item in massOptions" :key="item.label" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[4]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>


                <el-form-item label="滚阻系数" prop="mu">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-input class="my-el-input" v-model="form.mu" placeholder="请输入滚阻系数">
                      <template slot="append">
                        <el-button icon="el-icon-refresh-left" circle v-on:click="form.mu = 0.005"></el-button>
                      </template>
                    </el-input>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[5]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>
                <el-form-item label="传动效率" prop="ec">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-input v-model="form.ec" placeholder="请输入传动效率">
                      <template slot="append">
                        <el-button icon="el-icon-refresh-left" circle v-on:click="form.ec = 0.976">
                        </el-button>
                      </template>

                    </el-input>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[6]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>
                <el-form-item label="空气密度" prop="rho">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-input v-model="form.rho" placeholder="请输入空气密度">
                      <template slot="append">
                        <el-button icon="el-icon-refresh-left" circle v-on:click="form.rho = 1.2">
                        </el-button>
                      </template>

                    </el-input>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[7]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>

                <el-form-item label="重力常数" prop="g">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-input v-model="form.g" placeholder="请输入重力常数">
                      <template slot="append">
                        <el-button icon="el-icon-refresh-left" circle v-on:click="form.g = 9.8">
                        </el-button>
                      </template>
                    </el-input>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[8]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>


                <el-form-item label="修正系数" prop="factor">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-input v-model="form.factor" placeholder="请输入修正系数">
                      <template slot="append">
                        <el-button icon="el-icon-refresh-left" circle v-on:click="form.factor = 1">
                        </el-button>
                      </template>
                    </el-input>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[9]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>


                <el-form-item label="自定义CdA" prop="myCda">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-input v-model="form.myCda" placeholder="可输入自定义CdA">
                      <template slot="append">
                        <el-button icon="el-icon-refresh-left" circle v-on:click="form.myCda = null">
                        </el-button>
                      </template>
                    </el-input>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[10]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>

                <el-form-item label="自定义路段" prop="myRoad">
                  <div style="display: flex; align-items: center; width: 100%;">
                    <el-input v-model="form.myRoad" placeholder="可输入自定义路段">
                      <template slot="append">
                        <el-button icon="el-icon-refresh-left" circle v-on:click="form.myRoad = null">
                        </el-button>
                      </template>
                    </el-input>
                    <el-tooltip effect="light" popper-class="tps" class="item" placement="right-start">
                      <div slot="content" class="tooltip-content" v-html="tooltipContent[11]"></div>
                      <el-button class="set-other-btn" size="mini">?</el-button>
                    </el-tooltip>
                  </div>
                </el-form-item>


                <br/>


                <el-button type="success" @click="onSubmit" style="width:100%">
                  <i class="el-icon-loading" v-if="loading"></i>
                  计算
                </el-button>

              </el-form>
            </div>
          </div>

        </el-col>
        <el-col :xs="24" :sm="8" :md="9" :lg="9" :xl="9">
          <div class="grid-content res-div">
            <div class="title2">齿比分析</div>
            <div class="charts-parents">

              <div id="ratio-chart" class="chart-container" v-loading="loading"></div>
              <div id="rpm-u-chart" class="chart-container" v-loading="loading"></div>
            </div>

          </div>
        </el-col>

        <el-col :xs="24" :sm="8" :md="9" :lg="9" :xl="9">
          <div class="grid-content res-div">
            <div class="title2">功率分析</div>
            <div class="charts-parents">
              <div id="p-u-chart" class="chart-container" v-loading="loading"></div>
              <div id="p-s-chart" class="chart-container" v-loading="loading"></div>
            </div>

          </div>
        </el-col>
      </el-row>

    </el-main>
    <!-- <el-footer>Footer</el-footer> -->
  </el-container>
</template>


<script>
import {marked} from 'marked';

//判断是否为手机的宽度标准
const maxMobileWidth = 767;

export default {
  name: "app",
  components: {},
  data() {


    let rightNum = (rule, value, callback) => {//数字的校验规则
      if (value === null) {
        callback();
      } else if (isNaN(value)) {//true为字符串
        callback(new Error('请输入数字值'));
      } else if (value < 0) {
        callback(new Error('请输入非负数'));
        // } else if ((value.toString().length - value.toString().indexOf(".") - 1) > 4) {//true为小数位大于4的小数
      } else if (value.toString().includes(".") && (value.toString().split(".")[1].length > 4)) {//true为小数位大于4的小数
        callback(new Error('小数的位数应不超过4位'));
      } else {
        callback();
      }
    };

    let LessOne = (rule, value, callback) => {//(0,1]范围的数字校验规则
      if (value > 1 || value == 0) {
        callback(new Error('请输入(0,1]范围的数字'));
      } else {
        callback();
      }
    };

    let rightdriveDisk = (rule, value, callback) => {//飞轮输入校验规则

      let numbers = value.split("-");

      if (numbers.length > 3) {
        callback(new Error('不支持三盘以外的多盘系统'));
      }
      // 遍历数组中的每个数字部分，检查是否符合条件
      for (let i = 0; i < numbers.length; i++) {
        let num = parseInt(numbers[i]); // 将字符串转换为整数

        if (isNaN(num)) {//true为字符串
          callback(new Error('请输入以"-"分隔的数字组'));
        }
        // 检查数字是否是大于等于5且小于等于80的正整数
        if (isNaN(num) || num < 5 || num > 80 || num % 1 !== 0) {
          callback(new Error('齿数范围为大于等于5且小于等于80的正整数'));
        }

        // 检查数字部分是否顺序由大到小
        if (i > 0 && num >= parseInt(numbers[i - 1])) {
          callback(new Error('齿数顺序由大到小'));
        }
      }

      callback();
    };

    let rightFlywheel = (rule, value, callback) => {//飞轮输入校验规则

      let numbers = value.split("-");

      // 遍历数组中的每个数字部分，检查是否符合条件
      for (let i = 0; i < numbers.length; i++) {
        let num = parseInt(numbers[i]); // 将字符串转换为整数

        if (isNaN(num)) {//true为字符串
          callback(new Error('请输入以"-"分隔的数字组'));
        }
        // 检查数字是否是大于等于5且小于等于80的正整数
        if (isNaN(num) || num < 5 || num > 80 || num % 1 !== 0) {
          callback(new Error('齿数范围为大于等于5且小于等于80的正整数'));
        }

        // 检查数字部分是否顺序由小到大
        if (i > 0 && num <= parseInt(numbers[i - 1])) {
          callback(new Error('齿数顺序由小到大'));
        }
      }

      callback();
    };

    let rightMyCda = (rule, value, callback) => {//飞轮输入校验规则

      // 未输入参数时，不进行校验
      if (!value) {
        callback();
      }

      const regex = /^\d+(\.\d+)?(,\d+(\.\d+)?)*$/;
      if (!regex.test(value)) {
        callback(new Error('请输入以英文","分隔的非负数字组'));
      }

      callback();
    };
    let rightMyRoad = (rule, value, callback) => {//飞轮输入校验规则

      // 未输入参数时，不进行校验
      if (!value) {
        callback();
      }

      // 分割输入字符串
      const parts = value.split(',');

      // 确认是否有两部分
      if (parts.length !== 2) {
        callback(new Error('应输入距离及坡度，用英文","分隔'));
      }

      // 验证第一部分：非零的任意正整数
      const part1Regex = /^(0\.\d*[1-9]\d*|[1-9]\d*(\.\d+)?)$/;
      if (!part1Regex.test(parts[0])) {
        callback(new Error('参数1为非零正数'));
      }

      // 验证第二部分：保留一位小数的百分数
      const part2Regex = /^-?\d+(\.\d)?%$/;
      if (!part2Regex.test(parts[1])) {
        callback(new Error('参数2应为最多保留一位小数的百分数'));
      }


      callback();
    };


    return {
      loading: false,
      tooltipContent: [
        "牙盘组各盘片齿数。<br>示例说明：46-33T，表示靠近右脚的大盘为45齿，靠近左脚的小盘为10齿。<br>可自定义输入单盘、双盘、三盘牙盘组齿数，多盘从大到小排列并用“-”分隔。<br>自定义输入示例：53-48-34。",
        "飞轮的片数以及每片的齿数。<br>示例说明：12S 10-45T，表示有12片飞轮，最小齿为10齿，最大齿为45齿。<br>注：不同品牌的飞轮即使速别、最小齿、最大齿都相同，但是中间齿可能不同。<br>可自定义输入飞轮各齿数，各齿从小到大排列并用“-”分隔，<br>自定义输入示例：11-12-14-16-17-18-21-25-28。",
        "公路车或山地车的轮胎规格，该规格用于计算其周长。<br>示例说明：700 x 25C，通常表示公路车外胎，直径为700毫米，宽度为25毫米。26 * 1.95，通常表示山地车轮组，直径为26英寸，轮胎截面宽度为1.95英寸。<br>可自定义输入车轮周长，单位为mm。<br>自定义输入示例：2109",
        "每分钟踩踏的圈数，常用的竞速踏频为90左右，用于规划踏频-车速曲线、最小齿比爬坡曲线中的踏频范围。<br>示例说明：90 r/min，踏频-车速曲线中的踏频显示(90-40,90+40)区间范围，(90-5,90+5)区间为舒适踏频范围。",
        "车手和自行车的总质量，将影响爬坡做功、滚动阻力。<br>注：单位为kg。<br>自定义输入示例：67.25",
        "计算滚动阻力的系数。数值越大，相同车速下的滚阻越大。<br>默认值：0.005。",
        "骑行过程中，因为自行车形变、传动摩擦造成的能量损失。范围为(0,1)，且越接近1能量损失越小。<br>默认值：0.976。",
        "表示每单位体积空气的质量，该值将影响空气阻力计算。空气密度通常会随着气压，温度，湿度的变化而变化。<br>默认值：1.2 kg/m³。",
        "重力加速度，该值将影响重力做功和滚阻计算。在地球表面附近，重力加速度的标准值约为9.8 m/s²。<br>默认值：9.8 m/s²。",
        "风阻功率修正系数。受团队骑行、逆风骑行等实际户外骑行环境影响，理论计算所得的风阻功率和用户实际风阻功率之间可能存在较大误差。为修正计算结果，计算所得的理论风阻功率将乘以修正系数以接近用户实际风阻功率。<br>示例说明：0.8，风阻公式计算所得的风阻功率将会乘以0.8作为最后的显示结果。<br>默认值：1",
        "CdA，即空气阻力系数Cd*迎风面积A，该参数用于计算不同车速下的风阻，是车手和自行车形成的整体数值。因其需要通过风动实验或专业分析软件获取，各种骑行姿势下的CdA已经根据 <b><i>更多说明</i></b> 提及的文献中车手体型、自行车数据预设。填入自定义CdA将覆盖预设CdA数组，自定义输入多个数值之间用英文“,”隔开。注：单位为m²。<br>自定义输入示例：0.35,0.335,0.314。",
        "用于功率-车速曲线的路段信息，路段距离将影响时间计算结果。填入自定义路段将覆盖预设值，同时自定义非0%的坡度时，图中将不会出现选择坡度的滑动条。预设路段为距离10km，坡度0%。<br>注：坡度并非用角度表示，而是角度的正切值，通常用百分数表示。距离单位为km。<br>自定义输入格式为用英文“,”分隔开的距离和坡度。<br>自定义输入示例：20,4%。",
      ],

      columnWidth: null,
      drawerWidth: null,
      dialogWidth: null,
      html: null,
      tabDisabled: true,
      drawer: false,
      centerDialogVisible: false,
      isMobile: null,
      ratioChart: null,
      RPMUChart: null,
      PSChart: null,
      rules: {//修改表单的验证规则
        driveDiskArrStr: [
          {required: true, message: '请选择牙盘规格', trigger: 'blur'},
          {validator: rightdriveDisk, trigger: 'change'},
        ],
        flywheelArrStr: [
          {required: true, message: '请选择飞轮规格', trigger: 'blur'},
          {validator: rightFlywheel, trigger: 'change'},
        ],
        wheel: [
          {required: true, message: '请选择轮组规格', trigger: 'blur'},
          {validator: rightNum, trigger: 'change'},
        ],
        mass: [
          {required: true, message: '请选择人车质量', trigger: 'blur'},
          {validator: rightNum, trigger: 'change'},
        ],
        stepFrequency: [
          {required: true, message: '请选择常用踏频', trigger: 'blur'},
        ],
        mu: [
          {required: true, message: '请输入滚阻系数', trigger: 'blur'},
          {validator: rightNum, trigger: 'blur'},
        ],
        ec: [
          {required: true, message: '请输入传动效率', trigger: 'blur'},
          {validator: rightNum, trigger: 'blur'},
          {validator: LessOne, trigger: 'blur'},
        ],
        rho: [
          {required: true, message: '请输入空气密度', trigger: 'blur'},
          {validator: rightNum, trigger: 'blur'},
        ],
        g: [
          {required: true, message: '请输入重力常数', trigger: 'blur'},
          {validator: rightNum, trigger: 'blur'},
        ],
        factor: [
          {required: true, message: '请输入修正系数', trigger: 'blur'},
          {validator: rightNum, trigger: 'blur'},
        ],
        myCda: [
          {validator: rightMyCda, trigger: 'blur'},
        ],
        myRoad: [
          {validator: rightMyRoad, trigger: 'blur'},
        ],
      },
      form: {

        driveDiskArrStr: null,
        flywheelArrStr: null,
        wheel: null,
        stepFrequency: null,
        mass: null,
        myCda: null,
        myRoad: null,
        mu: 0.005,//滚阻系数
        ec: 0.976,//机械效率
        rho: 1.2,//空气密度
        g: 9.8,//重力加速度
        factor: 1,//修正系数，用于修正空气阻力中的计算偏差

      },
      driveDiskArr: null,
      flywheelArr: null,

      activeName: 'ratio-chart',
      iArr: [],
      //最小齿比爬坡曲线中的坡度
      slopeArr: [0.04, 0.06, 0.08, 0.1, 0.12, 0.14, 0.16, 0.18, 0.2],
      //功率车速曲线中的可调坡度
      PUPlotSlopeArr: [],
      fw: 0.0044,//轮组转动引起的空阻系数和迎风面积


      //齿比曲线
      ratioPlot: {
        title: {
          text: '齿比曲线',
          left: 'left',
          textStyle: {
            color: 'rgb(102, 177, 255)',
            fontSize: '14',
            fontWeight: '300',
            fontFamily: 'sans-serif'
          }
        },
        tooltip: { //鼠标悬浮提示信息
          trigger: 'axis', //折线图的线上
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = {top: 60};
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          textStyle: {
            fontSize: 12
          },
          backgroundColor: 'rgba(250,250,250,0.8)',
          axisPointer: {
            type: 'cross'
          }
        },

        toolbox: { //工具箱，如另存图片
          top: 20,
          feature: {
            saveAsImage: {
              name: "bicycle.noby.site"
            }, //保存图片
            // magicType: { show: true, type: ['line', 'bar'] },
            dataView: {}, //数据框
          }
        },
        // graphic: [{
        //   type: 'group',
        //   bounding: 'raw',
        //   // rotation: Math.PI / 4,//正方形旋转的角度
        //   left: '25%',
        //   top: '25%',
        //   z: 100,
        //   children: [
        //     {
        //       type: 'rect',
        //       left: 'center',//描述怎么根据父元素进行定位
        //       top: 'center',//描述怎么根据父元素进行定位
        //       z: 100,
        //       shape: {
        //         width: 140,
        //         height: 15
        //       },
        //       style: {
        //         fill: 'rgba(0,0,0,0)'
        //       }
        //     },
        //     {
        //       type: 'text',
        //       left: 'center',
        //       top: 'center',
        //       z: 100,
        //       style: {
        //         fill: 'rgba(0,0,0,0.08)',
        //         text: 'https://bicycle.noby.site',
        //         font: 'bolder 18px Microsoft YaHei'
        //       }
        //     }
        //   ]
        // }],
        animation: true, //图例翻页动画
        grid: { //网格配置，控制图表显示部分大小
          show: true,
          top: 60,
          left: 8,
          right: 12,
          bottom: 32,
          containLabel: true, //显示坐标轴上的数据
          // backgroundColor: 'rgba(120, 140, 200, 0.2)'
        },
        legend: { //图例组件
          selectedMode: false,
          type: 'scroll',
          icon: 'none',
          bottom: 0,
          itemWidth: 0,
          itemHeight: 0,
          itemGap: 6,
          textStyle: {
            color: 'inherit',
            fontSize: 12
          }
        },
        xAxis: {
          type: 'category',
          name: '齿数',
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          boundaryGap: false,
          data: null
        },
        yAxis: [{
          type: 'value',
          name: '齿比',
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
        }],
        series: [ //系列图表配置，决定显示的图标类型
          // {
          //   type: 'line',
          //   showSymbol: true,
          //   clip: true,
          //   showBackgroud: true,
          //   backgroudStyle: {
          //     color: 'rgba(180,180,180,0.2)'
          //   }
          //   //data: this.iArr,
          // }
        ],
      },

      //车速踏频曲线
      RPMUPlot: {
        title: {
          text: '踏频-车速曲线',
          left: 'left',
          textStyle: {
            color: 'rgb(102, 177, 255)',
            fontSize: '14',
            fontWeight: '300',
            fontFamily: 'sans-serif'
          }
        },
        tooltip: { //鼠标悬浮提示信息
          trigger: 'axis', //折线图的线上
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = {top: 60};
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          textStyle: {
            fontSize: 12
          },
          backgroundColor: 'rgba(250,250,250,0.8)',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: { //图例组件
          selectedMode: false,
          type: 'scroll',
          icon: 'none',
          bottom: 0,
          itemWidth: 0,
          itemHeight: 0,
          itemGap: 6,
          textStyle: {
            color: 'inherit',
            fontSize: 12
          }
        },
        toolbox: { //工具箱，如另存图片
          top: 20,
          feature: {
            saveAsImage: {
              name: "bicycle.noby.site"
            }, //保存图片
            dataView: {}, //数据框


          }
        },
        animation: true, //图例翻页动画
        grid: { //网格配置，控制图表显示部分大小
          show: true,
          top: 60,
          left: 8,
          right: 12,
          bottom: 32,
          containLabel: true, //显示坐标轴上的数据
          // backgroundColor: 'rgba(120, 140, 200, 0.2)'
        },
        xAxis: {
          name: '车速(km/h)',
          boundaryGap: true, //图表显示部分和坐标轴是否有缝隙
          min: 0,
          // max: function (value) {
          //    return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: { //是否显示刻度线
            show: true
          },
          minorSplitLine: { //是否显示分割线
            show: true
          },
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
        },
        yAxis: [{
          name: '踏频(r/min)',
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          min: function (value) {
            return Math.round(value.min - 10);
          },
          // min: null,
          // max: function (value) {
          //   return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: {
            show: true
          },
          minorSplitLine: {
            show: true
          },
          axisLabel: {}
        }],
        timeline: {
          left: '40%',
          right: '40%',
          top: 20,
          axisType: 'category',
          symbol: 'circle',
          symbolSize: 16,
          controlStyle: {show: false},//显示播放控件
          currentIndex: 0,//初始标记位置
          tooltip: {
            show: false,
            // formatter: function (value) { return Math.round(value * 100) + '%' },//显示的标记格式'
          },
          checkpointStyle: {
            color: "rgb(102, 177, 255)",
          },
          lineStyle: {
            show: false,
            color: "#dae1f5"
          },
          itemStyle: {
            color: "#dae1f5",
          },
          label: {
            color: "#788094",
            interval: 0,//标记显示的间隔
            //显示的标记格式，value表示的是data的元素对象的value值
            formatter: function (value) {
              return value + 'T'
            },
            position: 10
          },

          //左边的颜色
          progress: {
            lineStyle: {
              color: "#dae1f5"
            },
            itemStyle: {
              color: "#dae1f5",
            },
            label: {
              color: "#788094",
            },
          },

          //高亮的颜色
          emphasis: {
            itemStyle: {
              color: "rgba(102, 177, 255,0.5)",
            }

          },
          // padding: [
          //   5,  // 上
          //   100, // 右
          //   5,  // 下
          //   100, // 左
          // ],

          data: null,
        },
        series: [ //系列图表配置，决定显示的图标类型 
          // {
          //   type: 'line',
          //   name: '踏频(RPM)',
          //   showSymbol: false,
          //   clip: true,
          //   data: [],
          //   markPoint: {
          //     symbol: 'circle',
          //     symbolSize: 5,
          //     label: {
          //       fontSize: 8,
          //       position: [0, -10]
          //     },
          //     data: [
          //       {
          //         name: '固定 x 像素位置',
          //         xAxis: 100,
          //       }
          //     ]
          //   },
          // }
        ]


      },


      //爬坡曲线
      PSURPMPlot: {
        title: {
          text: '最小齿比爬坡曲线',
          left: 'left',
          textStyle: {
            color: 'rgb(102, 177, 255)',
            fontSize: '14',
            fontWeight: '300',
            fontFamily: 'sans-serif'
          }
        },
        tooltip: { //鼠标悬浮提示信息
          trigger: 'axis', //折线图的线上
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = {top: 60};
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          textStyle: {
            fontSize: 12
          },
          backgroundColor: 'rgba(250,250,250,0.8)',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: { //图例组件
          selectedMode: false,
          type: 'scroll',
          icon: 'none',
          bottom: 0,
          itemWidth: 0,
          itemHeight: 0,
          itemGap: 6,
          textStyle: {
            color: 'inherit',
            fontSize: 12
          }
        },
        toolbox: { //工具箱，如另存图片
          top: 20,
          feature: {
            saveAsImage: {
              name: "bicycle.noby.site"
            }, //保存图片
            dataView: {}, //数据框
          }
        },

        animation: true, //图例翻页动画
        grid: { //网格配置，控制图表显示部分大小
          show: true,
          top: 60,
          left: 8,
          right: 12,
          bottom: 32,
          containLabel: true, //显示坐标轴上的数据
          // backgroundColor: 'rgba(120, 140, 200, 0.2)'
        },
        xAxis: [{
          name: '踏频(r/min)',
          boundaryGap: true, //图表显示部分和坐标轴是否有缝隙
          min: null,
          // max: function (value) {
          //    return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: { //是否显示刻度线
            show: true
          },
          minorSplitLine: { //是否显示分割线
            show: true
          },
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          data: [],
          axisLabel: {
            interval: 9, // 由于是从0开始计数，所以每隔9个显示一个标签，实际上就是每10个
          }
        }, {
          name: '车速(km/h)',
          boundaryGap: true, //图表显示部分和坐标轴是否有缝隙
          min: 0,
          // max: function (value) {
          //    return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: { //是否显示刻度线
            show: true
          },
          minorSplitLine: { //是否显示分割线
            show: true
          },
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          data: [],
          axisLabel: {
            interval: 9, // 由于是从0开始计数，所以每隔9个显示一个标签，实际上就是每10个
          }
        }],
        yAxis: [{
          name: '功率(W)',
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          min: null, //设置坐标轴范围
          // max: function (value) {
          //   return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: {
            show: true
          },
          minorSplitLine: {
            show: true
          },
          axisLabel: {}
        }],
        series: [ //系列图表配置，决定显示的图标类型

          // {
          //   type: 'line',
          //   name: '踏频(RPM)',
          //   showSymbol: false,
          //   clip: true,
          //   data: [],
          // }
        ]


      },

      //速度功率曲线
      PUPlot: {
        title: {
          text: '功率-车速曲线',
          left: 'left',
          textStyle: {
            color: 'rgb(102, 177, 255)',
            fontSize: '14',
            fontWeight: '300',
            fontFamily: 'sans-serif'
          }
        },
        tooltip: { //鼠标悬浮提示信息
          trigger: 'axis', //折线图的线上
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = {top: 60};
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },
          textStyle: {
            fontSize: 12
          },
          backgroundColor: 'rgba(250,250,250,0.8)',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: { //图例组件
          selectedMode: false,
          type: 'scroll',
          icon: 'none',
          bottom: 0,
          itemWidth: 0,
          itemHeight: 0,
          itemGap: 6,
          textStyle: {
            color: 'inherit',
            fontSize: 12
          }
        },
        toolbox: { //工具箱，如另存图片
          top: 20,
          feature: {
            saveAsImage: {
              name: "bicycle.noby.site"
            }, //保存图片
            dataView: {}, //数据框

          }
        },
        animation: true, //图例翻页动画
        grid: { //网格配置，控制图表显示部分大小
          show: true,
          top: 60,
          left: 8,
          right: 12,
          bottom: 32,
          containLabel: true, //显示坐标轴上的数据
          // backgroundColor: 'rgba(120, 140, 200, 0.2)'
        },
        dataZoom: [
          {
            type: 'slider',
            showDataShadow: false,
            brushSelect: false,
            height: 14,
            bottom: 20,
            startValue: 0,
            endValue: 500
          },

        ],
        xAxis: [{
          name: '车速(km/h)',
          boundaryGap: true, //图表显示部分和坐标轴是否有缝隙
          min: 0,
          // max: function (value) {
          //    return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: { //是否显示刻度线
            show: true
          },
          minorSplitLine: { //是否显示分割线
            show: true
          },
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          data: [],
          axisLabel: {
            interval: 99, // 由于是从0开始计数，所以每隔9个显示一个标签，实际上就是每10个
          }
        }, {
          name: '时间(h:m:s)',
          boundaryGap: true, //图表显示部分和坐标轴是否有缝隙
          min: 0,
          // max: function (value) {
          //    return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: { //是否显示刻度线
            show: true
          },
          minorSplitLine: { //是否显示分割线
            show: true
          },
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          data: [],
          axisLabel: {
            interval: 99, // 由于是从0开始计数，所以每隔9个显示一个标签，实际上就是每10个
          }
        }
        ],

        yAxis: {
          name: '功率(W)',
          nameLocation: 'middle',
          nameGap: -20, //轴名字移动
          min: null, //设置坐标轴范围
          // max: function (value) {
          //   return Math.round(value.max * 1.2);
          // },
          max: null,
          minorTick: {
            show: true
          },
          minorSplitLine: {
            show: true
          },
          axisLabel: {},

        },
        timeline: {
          top: 20,
          axisType: 'category',
          symbol: 'circle',
          symbolSize: 3,
          controlStyle: {show: false},//显示播放控件
          currentIndex: 10,//初始标记位置
          tooltip: {
            show: false,
            // formatter: function (value) { return Math.round(value * 100) + '%' },//显示的标记格式'
          },


          checkpointStyle: {
            color: "rgb(102, 177, 255)",
          },
          lineStyle: {
            show: true,
            color: "#dae1f5"
          },
          itemStyle: {
            color: "#a4b1d7",
          },
          label: {
            color: "#788094",
            interval: 4,//标记显示的间隔
            //显示的标记格式，value表示的是data的元素对象的value值
            formatter: function (value) {
              return Math.round(value * 1000) / 10 + '%'
            },//显示的标记格式
            position: 10
          },

          //左边的颜色
          progress: {
            lineStyle: {
              color: "#dae1f5"
            },
            itemStyle: {
              color: "#a4b1d7",
            },
            label: {
              color: "#788094",
            },
          },

          //高亮的颜色
          emphasis: {
            itemStyle: {
              color: "rgba(102, 177, 255,0.5)",
            }

          },


          data: null,
        },
        series: [ //系列图表配置，决定显示的图标类型 
          // {
          //   type: 'line',
          //   name: '踏频(RPM)',
          //   showSymbol: false,
          //   clip: true,
          //   data: [],
          // }
        ],
        options: []


      },
    }

  },
  computed: {//计算属性

    cda() {
      let arr;
      if (this.form.myCda == null || this.form.myCda == '') {
        arr = [['手变位', 0.343], ['下把位', 0.332], ['下把曲肘位', 0.306], ['手变曲肘位', 0.295], ['TT位', 0.289]]
      } else {
        arr = []
        const parts = this.form.myCda.split(',');
        // 将parts从大到小排序
        parts.sort(function (a, b) {
          return b - a;
        });

        for (let i = 0; i < parts.length; i++) {
          arr.push([parts[i], Number(parts[i])]);
        }
      }
      return arr
    },
    road() {
      let road;
      if (this.form.myRoad == null || this.form.myRoad == '') {
        road = [10, 0]
      } else {
        let str = this.form.myRoad.split(',')[1];
        let num = Number(str.slice(0, -1)) / 100;
        road = [this.form.myRoad.split(',')[0], num]
        console.log("road::::" + road)
      }
      return road
    },
    driveDiskOptions() {
      let driveDiskOption = [{
        label: '2x', options: [
          {label: '46-33 T', value: '46-33'},
          {label: '48-34 T', value: '48-34'},
          {label: '48-35 T', value: '48-35'},
          {label: '50-34 T', value: '50-34'},
          {label: '50-37 T', value: '50-37'},
          {label: '52-36 T', value: '52-36'},
          {label: '53-39 T', value: '53-39'},
          {label: '54-40 T', value: '54-40'},
          {label: '54-42 T', value: '54-42'},
          {label: '55-42 T', value: '55-42'},
          {label: '56-44 T', value: '56-44'},
        ]
      }, {label: '1x', options: []}];
      for (let i = 20; i <= 60; i++) {
        driveDiskOption[1].options.push({label: i + ' T', value: i.toString()})
      }
      return driveDiskOption;
    },
    flywheelOptions() {
      return [{
        label: 'SHIMANO', options: [{
          label: '12S 10-45T',
          value: '10-12-14-16-18-21-24-28-32-36-40-45',
        }, {
          label: '12S 10-51T',
          value: '10-12-14-16-18-21-24-28-33-39-45-51',
        }, {
          label: '12S 11-30T',
          value: '11-12-13-14-15-16-17-19-21-24-27-30',
        }, {
          label: '12S 11-34T',
          value: '11-12-13-14-15-17-19-21-24-27-30-34',
        }, {
          label: '12S 11-36T',
          value: '11-12-13-14-15-17-19-21-24-28-32-36',
        }, {
          label: '11S 11-23T',
          value: '11-12-13-14-15-16-17-18-19-21-23',
        }, {
          label: '11S 11-25T',
          value: '11-12-13-14-15-16-17-19-21-23-25',
        }, {
          label: '11S 11-28T',
          value: '11-12-13-14-15-17-19-21-23-25-28',
        }, {
          label: '11S 11-30T',
          value: '11-12-13-14-15-17-19-21-24-27-30',
        }, {
          label: '11S 11-32T',
          value: '11-12-13-14-16-18-20-22-25-28-32',
        }, {
          label: '11S 11-34T',
          value: '11-13-15-17-19-21-23-25-27-30-34',
        }, {
          label: '11S 11-40T',
          value: '11-13-15-17-19-21-24-27-31-35-40',
        }, {
          label: '11S 11-42T',
          value: '11-13-15-17-19-21-24-28-32-37-42',
        }, {
          label: '11S 11-51T',
          value: '11-13-15-18-21-24-28-33-39-45-51',
        }, {
          label: '11S 12-25T',
          value: '12-13-14-15-16-17-18-19-21-23-25',
        }, {
          label: '11S 12-28T',
          value: '12-13-14-15-16-17-19-21-23-25-28',
        }, {
          label: '10S 11-25T',
          value: '11-12-13-14-15-17-19-21-23-25',
        }, {
          label: '10S 11-32T',
          value: '11-12-14-16-18-20-22-25-28-32',
        }, {
          label: '10S 11-34T',
          value: '11-13-15-17-19-21-23-26-30-34',
        }, {
          label: '10S 11-36T',
          value: '11-13-15-17-19-21-24-28-32-36',
        }, {
          label: '10S 11-42T',
          value: '11-13-15-18-21-24-28-32-37-42',
        }, {
          label: '10S 11-43T',
          value: '11-13-15-17-20-23-26-30-36-43',
        }, {
          label: '10S 11-46T',
          value: '11-13-15-18-21-24-28-32-37-46',
        }, {
          label: '10S 12-28T',
          value: '12-13-14-15-17-19-21-23-25-28',
        }, {
          label: '10S 12-30T',
          value: '12-13-14-15-17-19-21-24-27-30',
        }, {
          label: '9S 11-25T',
          value: '11-12-13-15-17-19-21-23-25',
        }, {
          label: '9S 11-30T',
          value: '11-12-14-16-18-20-23-26-30',
        }, {
          label: '9S 11-32T',
          value: '11-13-15-17-19-21-24-28-32',
        }, {
          label: '9S 11-34T',
          value: '11-13-15-17-20-23-26-30-34',
        }, {
          label: '9S 11-36T',
          value: '11-13-15-17-20-23-26-30-36',
        }, {
          label: '9S 12-25T',
          value: '12-13-14-15-17-19-21-23-25',
        }, {
          label: '9S 13-25T',
          value: '13-14-15-16-17-19-21-23-25',
        }, {
          label: '9S 14-25T',
          value: '14-15-16-17-18-19-21-23-25',
        }, {
          label: '8S 11-28T',
          value: '11-13-15-17-19-21-24-28',
        }, {
          label: '8S 11-30T',
          value: '11-13-15-17-20-23-26-30',
        }, {
          label: '8S 11-32T',
          value: '11-13-15-18-21-24-28-32',
        }, {
          label: '8S 11-34T',
          value: '11-13-15-18-21-24-28-34',
        }, {
          label: '8S 11-40T',
          value: '11-13-15-18-22-27-33-40',
        }, {
          label: '8S 12-23T',
          value: '12-13-14-15-17-19-21-23',
        }, {
          label: '8S 12-25T',
          value: '12-13-15-17-19-21-23-25',
        }, {
          label: '8S 13-26T',
          value: '13-14-15-17-19-21-23-26',
        }]
      }, {
        label: 'SRAM',
        options: [
          {
            label: '12S 10-26T',
            value: '10-11-12-13-14-15-16-17-19-21-23-26',
          },
          {
            label: '12S 10-28T',
            value: '10-11-12-13-14-15-16-17-19-21-24-28',
          },
          {
            label: '12S 10-30T',
            value: '10-11-12-13-14-15-17-19-21-24-27-30',
          },
          {
            label: '12S 10-33T',
            value: '10-11-12-13-14-15-17-19-21-24-28-33',
          },
          {
            label: '12S 10-36T',
            value: '10-11-12-13-15-17-19-21-24-28-32-36',
          },
          {
            label: '12S 10-44T',
            value: '10-11-13-15-17-19-21-24-28-32-38-44',
          },
          {
            label: '12S 10-50T',
            value: '10-12-14-16-18-21-24-28-32-36-42-50',
          },
          {
            label: '12S 10-52T',
            value: '10-12-14-16-18-21-24-28-32-36-42-52',
          },
          {
            label: '12S 11-50T',
            value: '11-13-15-17-19-22-25-28-32-36-42-50',
          },
          {
            label: '11S 10-42T',
            value: '10-12-14-16-18-21-24-28-32-36-42',
          },
          {
            label: '11S 11-25T',
            value: '11-12-13-14-15-16-17-19-21-23-25',
          },
          {
            label: '11S 11-26T',
            value: '11-12-13-14-15-16-17-19-21-23-26',
          },
          {
            label: '11S 11-28T',
            value: '11-12-13-14-15-16-17-19-22-25-28',
          },
          {
            label: '11S 11-30T',
            value: '11-12-13-14-15-17-19-21-24-27-30',
          },
          {
            label: '11S 11-32T',
            value: '11-12-13-14-15-17-19-22-25-28-32',
          },
          {
            label: '11S 11-36T',
            value: '11-12-13-15-17-19-22-25-28-32-36',
          },
          {
            label: '11S 11-42T',
            value: '11-13-15-17-19-22-25-28-32-36-42',
          },
          {
            label: '10S 11-23T',
            value: '11-12-13-14-15-16-17-19-21-23',
          },
          {
            label: '10S 11-25T',
            value: '11-12-13-14-15-17-19-21-23-25',
          },
          {
            label: '10S 11-26T',
            value: '11-12-13-14-15-17-19-21-23-26',
          },
          {
            label: '10S 11-28T',
            value: '11-12-13-14-15-17-19-22-25-28',
          },
          {
            label: '10S 11-32T',
            value: '11-12-13-15-17-19-22-25-28-32',
          },
          {
            label: '10S 11-36T',
            value: '11-13-15-17-19-22-25-28-32-36',
          },
          {
            label: '10S 12-25T',
            value: '12-13-14-15-16-17-19-21-23-25',
          },
          {
            label: '10S 12-26T',
            value: '12-13-14-15-16-17-19-21-23-26',
          },
          {
            label: '10S 12-27T',
            value: '12-13-14-15-16-17-19-21-24-27',
          },
          {
            label: '10S 12-28T',
            value: '12-13-14-15-16-17-19-22-25-28',
          },
          {
            label: '10S 12-32T',
            value: '12-13-14-15-17-19-22-25-28-32',
          },
          {
            label: '10S 12-36T',
            value: '12-13-15-17-19-22-25-28-32-36',
          },
          {
            label: '9S 11-26T',
            value: '11-13-14-15-17-19-21-23-26',
          },
          {
            label: '9S 11-28T',
            value: '11-12-13-14-16-18-21-24-28',
          },
          {
            label: '9S 11-32T',
            value: '11-12-14-16-18-21-24-28-32',
          },
          {
            label: '9S 11-34T',
            value: '11-13-15-17-20-23-26-30-34',
          },
          {
            label: '9S 12-23T',
            value: '12-13-14-15-16-17-19-21-23',
          },
          {
            label: '9S 12-26T',
            value: '12-13-14-15-17-18-21-23-26',
          },
          {
            label: '8S 11-28T',
            value: '11-12-14-16-18-21-24-28',
          },
          {
            label: '8S 11-30T',
            value: '11-13-15-17-20-23-26-30',
          },
          {
            label: '8S 11-32T',
            value: '11-12-14-16-18-21-26-32',
          },
          {
            label: '8S 11-48T',
            value: '11-13-15-18-24-32-40-48',
          },
          {
            label: '8S 12-26T',
            value: '12-13-15-17-19-21-23-26',
          },
          {
            label: '7S 10-24T',
            value: '10-12-14-16-18-21-24',
          },
          {
            label: '7S 11-25T',
            value: '11-13-15-17-19-22-25',
          },
          {
            label: '7S 12-32T',
            value: '12-14-16-18-21-26-32',
          },

        ]
      }]
    },
    wheelOptions() {
      return [{
        label: '公路车',
        options: [{
          label: '700 × 23C',
          value: 2096,
        }, {
          label: '700 × 25C',
          value: 2105,
        }, {
          label: '700 × 28C',
          value: 2136,
        }, {
          label: '700 × 30C',
          value: 2146,
        }, {
          label: '700 × 32C',
          value: 2155,
        }, {
          label: '700C Tubular',
          value: 2130,
        }, {
          label: '700 × 35C',
          value: 2168,
        }, {
          label: '700 × 38C',
          value: 2180,
        }, {
          label: '700 × 40C',
          value: 2200,
        }]
      }, {
        label: '山地车',
        options: [
          {
            label: '26 × 1.25',
            value: 1950,
          }, {
            label: '26 × 1.50',
            value: 2010,
          }, {
            label: '26 × 1.75',
            value: 2023,
          }, {
            label: '26 × 1.95',
            value: 2050,
          }, {
            label: '26 × 2.10',
            value: 2068,
          }, {
            label: '26 × 2.125',
            value: 2070,
          }, {
            label: '26 × 2.35',
            value: 2083,
          }, {
            label: '27.5 × 1.5',
            value: 2079,
          }, {
            label: '27.5 × 1.95',
            value: 2090,
          }, {
            label: '27.5 × 2.10',
            value: 2148,
          }, {
            label: '27.5 × 2.25',
            value: 2182,
          }, {
            label: '27.5 × 2.30',
            value: 2202,
          }, {
            label: '29 × 2.10',
            value: 2288,
          }, {
            label: '29 × 2.20',
            value: 2298,
          }, {
            label: '29 × 2.30',
            value: 2326,
          }]
      }]
    },
    massOptions() {
      let massOption = [];
      for (let i = 20; i < 150; i++) {
        massOption.push({label: i + ' kg', value: i})
      }
      return massOption;
    },
    stepFrequencyOptions() {
      let stepFrequencyOption = [];
      for (let i = 40; i < 140; i++) {
        stepFrequencyOption.push({label: i + ' r/min', value: i})
      }
      return stepFrequencyOption;
    },
    comfortMinRPM: {
      get() {
        return this.form.stepFrequency - 5;
      }
    },
    comfortMaxRPM: {
      get() {
        return this.form.stepFrequency - 0 + 5;
      }
    },
    minRPM: {
      get() {
        let min = this.form.stepFrequency - 40;
        if (min < 20) {
          min = 20;
        }
        return min;
      }
    },
    maxRPM: {
      get() {
        return this.form.stepFrequency - 0 + 40;
      }
    },


  },

  methods: {
    onSubmit() {
      this.$refs["inputForm"].validate((valid) => {
        if (valid) {//表单验证通过开始计算

          this.loading = true;
          console.log("loading1 = " + this.loading);
          setTimeout(() => {
            //将表单字符串转换成可用数据
            this.parseForm();

            // 初始化功率-车速曲线中的预设坡度
            this.initPUPlotSlopeArr();

            // 初始化踏频-车速曲线中的牙盘
            this.initRPMUPlotdriveDiskArr();

            //输出齿比数组，从小到大
            this.putIArr();

            //输入RatioChart到echarts对象
            this.putRatioChart();

            //输入RPMUSeries到echarts对象
            this.putRPMUChart();

            //输入PSSeries到echarts对象
            this.putPSURPMChart();

            //输入PUSeries到echarts对象
            this.putPUChart();

            //控制台打印输入的数据
            this.dataShow();

            //在容器中绘制图表
            this.drawPlot()

            // 向浏览器存入数据
            this.saveToBrowserStorage();
            this.loading = false;
            console.log("loading2 = " + this.loading);
          }, 100);
        }
      });
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        // 进入全屏
        this.requestFullScreen(document.documentElement);
      } else {
        // 退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    requestFullScreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    },

    parseForm() {
      this.parseFlywheel();
      this.parseDriveDisk();
    },
    parseFlywheel() {
      this.flywheelArr = this.form.flywheelArrStr.split("-").map(Number);
    },
    parseDriveDisk() {
      this.driveDiskArr = this.form.driveDiskArrStr.split('-').map(Number);
    },
    //输出该车的齿比数组，从小到大(齿比为牙盘齿数比飞轮齿数)
    putIArr() {
      let arr = [];
      for (let i = 0; i < this.driveDiskArr.length; i++) {
        arr[i] = [];
        for (let j = 0; j < this.flywheelArr.length; j++) {
          arr[i][j] = (this.driveDiskArr[this.driveDiskArr.length - 1 - i] / this.flywheelArr[this.flywheelArr.length - 1 - j]).toFixed(2);
        }
      }
      this.iArr = arr;
    },


    //在PUPlot中初始化预设坡度
    initPUPlotSlopeArr() {
      // 初始化PUPlotSlopeArr和slopeObjArr数组
      this.PUPlotSlopeArr = [];
      let slopeObjArr = [];
      let slope = this.road[1] - 0.1;
      let step = 0;

      // 根据this.road[1]的值设置timeline的currentIndex
      this.PUPlot.timeline.currentIndex = this.road[1] !== 0 ? 0 : 10;

      if (this.road[1] !== 0) {
        // 如果road[1]不为0，只添加一个对象
        let value = this.road[1];
        slopeObjArr.push({
          value: value,
          symbol: 'diamond',
          symbolSize: 7,
        });
        this.PUPlotSlopeArr.push(value);
      } else {
        // 如果road[1]为0，循环添加对象，直到slope超过road[1]+0.1
        while (slope.toFixed(3) <= this.road[1] + 0.1) {
          let value = slope.toFixed(3);
          let symbol = step % 5 === 0 ? 'diamond' : 'circle';
          let symbolSize = step++ % 5 === 0 ? 7 : 3;

          slopeObjArr.push({value, symbol, symbolSize});
          this.PUPlotSlopeArr.push(value);
          slope += 0.01;
        }
      }

      this.PUPlot.timeline.data = slopeObjArr;
    },

    //在RPMUPlot中初始化预设牙盘
    initRPMUPlotdriveDiskArr() {
      //slice()复制一个数组元素而非地址值，reverse()会使原数组倒置
      this.RPMUPlot.timeline.data = this.driveDiskArr.slice().reverse();
    },
    drawPlot() {
      // 绘制图表
      this.ratioChart.setOption(this.ratioPlot, true);
      this.RPMUChart.setOption(this.RPMUPlot, true);
      this.PSChart.setOption(this.PSURPMPlot, true);
      this.PUChart.setOption(this.PUPlot, true);
    },

    //数据显示
    dataShow() {
      console.log("cda = " + this.cda);
      console.log("flywheelArr = " + this.flywheelArr);
      console.log("iArr = " + this.iArr);
      console.log("driveDiskArr = " + this.driveDiskArr);
      console.log("wheel = " + this.form.wheel);
      console.log("mass = " + this.form.mass);
      console.log("stepFrequency = " + this.form.stepFrequency);
      console.log("minRPM = " + this.minRPM);
      console.log("maxRPM = " + this.maxRPM);
      console.log("comfortMinRPM = " + this.comfortMinRPM);
      console.log("comfortMaxRPM = " + this.comfortMaxRPM);
      console.log("PUPlotSlopeArr = " + this.PUPlotSlopeArr);

      console.log('this.isMobile = ' + this.isMobile);

    },
    toggleDrawer() {
      this.drawer = !this.drawer;
      // this.parseMd();
    },

    //输出齿比曲线
    putRatioChart() {
      this.ratioPlot.xAxis.data = null;
      this.ratioPlot.series = [];

      let category = [];
      for (let i = this.flywheelArr.length - 1; i >= 0; i--) {
        category.push(this.flywheelArr[i] + 'T')
      }

      this.ratioPlot.xAxis.data = category;
      for (let i = 0; i < this.iArr.length; i++) {
        this.ratioPlot.series[this.iArr.length - 1 - i] = {
          name: this.driveDiskArr[this.iArr.length - 1 - i] + 'T',
          type: 'line',
          showSymbol: true,
          clip: true,
          showBackgroud: true,
          backgroudStyle: {
            color: 'rgba(180,180,180,0.2)'
          },
          data: this.iArr[i]
        }
      }


    },
    putRPMUChart() {
      this.RPMUPlot.series = [];
      this.RPMUPlot.options = new Array(this.driveDiskArr.length);
      for (let i = 0; i < this.RPMUPlot.options.length; i++) {
        this.RPMUPlot.options[i] = {series: []};
      }
      //添加options
      for (let i = 0; i < this.iArr.length; i++) {
        for (let j = 0; j < this.iArr[0].length; j++) {
          let seri = {
            type: 'line',
            name: this.flywheelArr[this.iArr[0].length - j - 1] + 'T',
            showSymbol: false,
            showName: true,
            clip: true,
            data: this.putRPMUData(this.iArr[i][j]),
          }
          this.RPMUPlot.options[i].series.push(seri)
        }

      }

      let markLine = {
        type: "line",
        markLine: {
          silent: true,
          symbol: "none", //去掉箭头
          itemStyle: {
            normal: {
              lineStyle: {
                type: "dashed", //线条样式
                color: "rgba(1,1,1,0.3)"
              },
              label: {
                show: true,
                position: "insideStart", // 文字显示的位置
                color: "rgb(100,100,100)", // 设置标签颜色为红色
                formatter: function (params) {
                  return '    ' + params.value; // 使用空格来模拟偏移，这里的空格数需要根据实际效果调整
                }
              },
            },
          },
          data: [
            {
              name: "",
              yAxis: this.minRPM,
            }, {
              name: "",
              yAxis: this.form.stepFrequency,
            }, {
              name: "",
              yAxis: this.maxRPM,
            },
          ],
        },
      };
      let markArea = {
        type: "line",
        markArea: {
          silent: true,
          label: {
            show: true,
            color: "rgba(1,1,1,0.6)",
            position: "insideRight", // 文字显示的位置
            offset: [0, 0]
          },
          itemStyle: {
            color: "rgba(0,255,0,0.2)",
            borderWidth: 1,
            borderType: "dotted",
            borderColor: "rgba(0,0,0,0.4)",
          },

          data: [
            [
              {
                name: '舒适踏频区',
                yAxis: this.comfortMinRPM
              },
              {
                yAxis: this.comfortMaxRPM
              }
            ],
          ]
        },
      }


      this.RPMUPlot.series.push(markLine);
      this.RPMUPlot.series.push(markArea);
    },


    putPSURPMChart() {
      // 清空踏频数据和车速数据
      this.PSURPMPlot.xAxis[0].data = [];
      this.PSURPMPlot.xAxis[1].data = [];
      // 清空功率数据
      this.PSURPMPlot.series = [];

      // 反向遍历坡度数组，为每个坡度生成功率-踏频(车速)数据系列
      this.slopeArr.slice().reverse().forEach(slope => {
        const arr = this.putPSURPMData(this.iArr[0][0], slope);
        // 功率数据
        const yArr = arr.map(item => item[2]);

        // 创建并添加新的系列配置
        this.PSURPMPlot.series.push({
          type: 'line',
          name: `${Math.round(slope * 100)}%`,
          showSymbol: false,
          clip: true,
          data: yArr,
          xAxisIndex: 0,
        });

        // 如果是第一次循环，设置踏频和车速数据
        if (slope === this.slopeArr[0]) {
          this.PSURPMPlot.xAxis[0].data = arr.map(item => item[0]); // 踏频数据
          this.PSURPMPlot.xAxis[1].data = arr.map(item => item[1]); // 车速数据
        }
      });
    },


    putPUChart() {
      // 遍历所有斜率值，为每个斜率生成一个图表配置
      this.PUPlot.options = this.PUPlotSlopeArr.map(slope => {
        // 初始化图表配置对象，包括x轴(车速和时间)和系列数据
        const option = {xAxis: [{data: []}, {data: []}], series: []};
        // 初始化用于收集所有车速和时间数据的数组
        let speedData = [];
        let timeData = [];

        // 遍历cda数组，每个元素包含名称和cda值
        this.cda.forEach(([name, cdaValue]) => {
          // 对于每个cda值，计算对应的功率数据
          const puData = this.putPUData(cdaValue, slope);
          // 将计算得到的功率数据(假设为数组中的第三个元素)添加到系列中
          option.series.push({
            type: 'line', // 线型图
            name, // 系列名称，即cda数组中的名称
            showSymbol: false, // 不显示标记点
            showName: true, // 显示系列名称
            clip: true, // 裁剪超出坐标轴部分的图形
            data: puData.map(data => data[2]) // 功率数据
          });

          // 收集所有的车速和时间数据
          speedData = puData.map(data => data[0]);
          timeData = puData.map(data => data[1]);
        });

        // 添加重力做功(爬坡)的数据系列
        const gravityWorkData = this.putPpeSUData(slope).map(data => data[1]);
        option.series.push({
          type: 'line',
          name: '爬坡',
          showSymbol: false,
          color: 'rgb(154, 96, 180)', // 自定义系列颜色
          showName: true,
          areaStyle: {opacity: 0.2}, // 区域填充样式
          clip: true,
          data: gravityWorkData
        });

        // 添加车轮和轴承滚阻的数据系列
        const rollingResistanceData = this.putPrrPwbUData().map(data => data[1]);
        option.series.push({
          type: 'line',
          name: '滚阻',
          showSymbol: false,
          color: 'rgb(255, 136, 224)', // 自定义系列颜色
          showName: true,
          areaStyle: {opacity: 0.2}, // 区域填充样式
          clip: true,
          data: rollingResistanceData
        });

        // 将收集到的车速和时间数据分别设置为x轴的数据
        option.xAxis[0].data = speedData;
        option.xAxis[1].data = timeData;

        return option;
      });
    },


    //一定齿比下，车速和踏频的关系
    putURPM(rpm, i) {
      return 3.6 * (rpm / 60) * i * this.form.wheel * 0.001;
    },
    //一定齿比下，踏频和车速的关系
    putRPMU(u, i) {
      return u * 60 / (3.6 * i * this.form.wheel * 0.001);
    },
    //一定齿比和坡度的条件下，功率和踏频的关系
    putPRPM(i, s, rpm) {
      return (s * this.putURPM(rpm, i) * this.form.g * this.form.mass) / (this.form.ec * 3.6) + this.putPU(this.putURPM(rpm, i), this.cda[0][1], 0);
    },
    //某齿比下的最低车速
    putUMin(i) {
      return this.putURPM(this.minRPM, i);
    },
    //某齿比下的最高车速
    putUMax(i) {
      return this.putURPM(this.maxRPM, i);
    },
    //滚动阻力、轴承阻力功率
    putPrrPwbU(u) {
      let prr = this.form.mu * this.form.mass * this.form.g * u / 3.6;//滚动阻力
      let pwb = (91 + 8.7 * u / 3.6) * u / 3.6 * 0.001;//车轮轴承阻力
      return prr + pwb;
    },
    //重力做功功率
    putPpeSU(s, u) {
      return (s * u * this.form.g * this.form.mass) / 3.6;
    },
    //空气阻力功率
    putPatU(cda, u) {
      return 0.5 * this.form.rho * (cda + this.fw) * Math.pow(u / 3.6, 3) * this.form.factor;
    },
    //车速和总阻力功率的对应关系
    putPU(u, cda, s) {
      return (this.putPatU(cda, u) + this.putPrrPwbU(u) + this.putPpeSU(s, u)) / this.form.ec;
    },
    //输出一定齿比条件下的，踏频-车速数据
    putRPMUData(i) {
      let data = [];
      for (let u = this.putUMin(i); u <= this.putUMax(i).toFixed(1); u += 0.1) {
        data.push([u.toFixed(1), this.putRPMU(u.toFixed(1), i).toFixed(1)]);
      }
      return data;
    },
    //输出一定齿比条件下的，与坡度有关的，功率-踏频(车速)数据
    putPSURPMData(i, s) {
      let data = [];
      for (let rpm = 10; rpm <= this.maxRPM; rpm += 1) {
        data.push([rpm, this.putURPM(rpm, i).toFixed(1), this.putPRPM(i, s, rpm).toFixed(1)]);
      }
      return data;
    },
    //输出功率和车速数据
    putPUData(cda, s) {
      let data = [];
      let distance = this.road[0];

      for (let u = 0; u <= 100; u += 0.1) {
        const timeHMS = this.convertTime(u === 0 ? '∞' : distance / u)
        data.push([u.toFixed(1), timeHMS, this.putPU(u.toFixed(1), cda, s).toFixed(1)]);
      }
      return data;
    },
    //时间转换
    convertTime(decimalHours) {
      if (decimalHours === '∞') {
        return '∞';
      }
      // 将小时转换为总秒数
      let totalSeconds = Math.round(decimalHours * 3600); // 直接在这里四舍五入，避免后面出现

      // 计算小时、分钟和秒
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600; // 更新剩余秒数
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60; // 直接使用余数，避免四舍五入到60

      // 格式化为 h:m:s，确保小时、分钟和秒数为两位数字
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    //输出滚动阻力功率和车速数据
    putPrrPwbUData() {
      let data = [];
      for (let u = 0; u <= 100; u += 0.1) {
        data.push([u.toFixed(1), this.putPrrPwbU(u.toFixed(1)).toFixed(1)]);
      }
      return data;
    },

    //输出重力阻力功率和车速数据
    putPpeSUData(s) {
      let data = [];
      for (let u = 0; u <= 100; u += 0.1) {
        data.push([u.toFixed(1), this.putPpeSU(u.toFixed(1), s).toFixed(1)]);
      }
      return data;
    },

    //判断是否为手机页面
    getIsMobile() {
      this.isMobile = window.matchMedia(`(max-width: ${maxMobileWidth}px)`).matches;
    },

    getDomSizeValues() {
      this.elHeaderHeight = document.querySelectorAll(".el-header")[0].offsetHeight;
      this.mainPadding = parseInt(window.getComputedStyle(document.querySelector("#main")).padding);
      this.resDivPaddingTop = parseInt(window.getComputedStyle(document.querySelectorAll(".res-div")[0]).paddingTop);
      this.resDivPaddingBottom = parseInt(window.getComputedStyle(document.querySelectorAll(".res-div")[0]).paddingBottom);
      this.resDivPaddingLeft = parseInt(window.getComputedStyle(document.querySelectorAll(".res-div")[0]).paddingLeft);
      this.resDivPaddingRight = parseInt(window.getComputedStyle(document.querySelectorAll(".res-div")[0]).paddingRight);
      this.title2Height = document.querySelectorAll(".title2")[0].offsetHeight;

      this.gridContentMinHeight = parseInt(window.getComputedStyle(document.querySelectorAll(".grid-content")[0]).minHeight);

      this.gridContentHeight = window.innerHeight - this.elHeaderHeight - this.mainPadding * 2;

      // this.chartContainerWidth = document.querySelectorAll(".chart-container")[0].offsetWidth;


    },
    initLayout() {
      this.getIsMobile();
      this.getDomSizeValues();
      if (this.isMobile) {//是移动端，
        //  main的padding为5
        this.changMianPadding(5)
        //  DrawerWidth为屏幕宽度
        this.setDrawerWidth(window.innerWidth);
        //  DialogWidth为屏幕宽度
        this.setDialogWidth(window.innerWidth);
        //  grid-content高度为gridContentMinHeight
        // this.changeGridContentHeight(this.gridContentMinHeight)
        // this.changeChartsParentsHeight(this.gridContentMinHeight - this.title2Height - this.resDivPaddingTop - this.resDivPaddingBottom)
        // this.changeChartContainerHeight((this.gridContentMinHeight - this.title2Height - this.resDivPaddingTop - this.resDivPaddingBottom) / 2)
        this.resizeChartContainerHeight((this.gridContentMinHeight - this.title2Height) / 2)
        // this.resizeChartContainer()

      } else {//不是移动端，


        //  main的padding为20
        this.changMianPadding(20);
        //  DrawerWidth宽度为maxMobileWidth
        this.setDrawerWidth(maxMobileWidth);
        // DialogWidth宽度为400px
        this.setDialogWidth(400);

        this.PcChangeLayout()
        window.addEventListener("resize", this.PcChangeLayout);

      }


    },

    PcChangeLayout() {
      this.getDomSizeValues();
      // this.resizeChartContainerWidth(this.chartContainerWidth)
      // this.resizeChartContainer()

      //  grid-content高度为gridContentHeight

      if (this.gridContentMinHeight < this.gridContentHeight - this.resDivPaddingTop - this.resDivPaddingBottom) {
        this.changeGridContentHeight(this.gridContentHeight)
        // this.changeChartsParentsHeight(this.gridContentHeight - this.title2Height - this.resDivPaddingTop - this.resDivPaddingBottom)
        // this.changeChartContainerHeight((this.gridContentHeight - this.title2Height - this.resDivPaddingTop - this.resDivPaddingBottom) / 2)
        this.resizeChartContainerHeight((this.gridContentHeight - this.title2Height - this.resDivPaddingTop - this.resDivPaddingBottom) / 2)
        // this.resizeChartContainer()

      } else {
        this.resizeChartContainerHeight((this.gridContentMinHeight - this.title2Height) / 2)
      }

    },

    resizeChartContainer() {
      this.RPMUChart.resize();
      this.ratioChart.resize();
      this.PSChart.resize();
      this.PUChart.resize();
    },
    resizeChartContainerWidth(value) {
      this.RPMUChart.resize({width: value + "px"})
      this.ratioChart.resize({width: value + "px"})
      this.PSChart.resize({width: value + "px"})
      this.PUChart.resize({width: value + "px"})
    },

    resizeChartContainerHeight(value) {
      this.RPMUChart.resize({height: value + "px"})
      this.ratioChart.resize({height: value + "px"})
      this.PSChart.resize({height: value + "px"})
      this.PUChart.resize({height: value + "px"})
    },

    changeGridContentHeight(value) {
      var gridContents = document.querySelectorAll(".grid-content");
      for (var i = 0; i < gridContents.length; i++) {
        var element = gridContents[i];
        element.style.height = value - this.resDivPaddingTop - this.resDivPaddingBottom + "px";
      }
    },

    changeChartsParentsHeight(value) {
      document.querySelectorAll(".charts-parents").forEach(function (element) {
        element.style.height = value + "px";
      });
    },
    changeChartContainerHeight(value) {
      document.querySelectorAll(".chart-container").forEach(function (element) {
        element.style.height = value + "px";
      });
    },


    //设置main的padding
    changMianPadding(value) {
      document.querySelector('#main').style.padding = value + 'px';
    },


    //设置抽屉的响应式
    setDrawerWidth(value) {
      this.drawerWidth = value + 'px';
    },
    //设置抽屉的响应式
    setDialogWidth(value) {
      this.dialogWidth = value + 'px';
    },

    // //解析md文件
    parseMd() {
      this.$axios.get('./md/info.md').then(res => {
        this.html = marked.parse(res.data);
        // 解析markdown中的数学公式
        // this.$nextTick(() => {
        //   if (window.MathJax) {
        //     window.MathJax.typesetPromise().catch(err => console.error('MathJax typesetPromise failed:', err));
        //   }
        // });
      }).catch(err => console.error('Failed to load markdown file:', err));
    },

    //将数据存入浏览器
    saveToBrowserStorage() {
      localStorage.setItem('driveDiskArrStr', JSON.stringify(this.form.driveDiskArrStr));
      localStorage.setItem('flywheelArrStr', JSON.stringify(this.form.flywheelArrStr));
      localStorage.setItem('wheel', JSON.stringify(this.form.wheel));
      localStorage.setItem('mass', JSON.stringify(this.form.mass));
      localStorage.setItem('stepFrequency', JSON.stringify(this.form.stepFrequency));
      localStorage.setItem('mu', JSON.stringify(this.form.mu));
      localStorage.setItem('ec', JSON.stringify(this.form.ec));
      localStorage.setItem('rho', JSON.stringify(this.form.rho));
      localStorage.setItem('g', JSON.stringify(this.form.g));
      localStorage.setItem('factor', JSON.stringify(this.form.factor));
      localStorage.setItem('myCda', JSON.stringify(this.form.myCda));
      localStorage.setItem('myRoad', JSON.stringify(this.form.myRoad));
    },
    //将数据从浏览器读取
    retrieveFromBrowserStorage() {
      let driveDiskArrStr = localStorage.getItem('driveDiskArrStr');
      if (driveDiskArrStr) {
        this.form.driveDiskArrStr = JSON.parse(driveDiskArrStr);
      }
      let flywheelArrStr = localStorage.getItem('flywheelArrStr');
      if (flywheelArrStr) {
        this.form.flywheelArrStr = JSON.parse(flywheelArrStr);
      }
      let wheel = localStorage.getItem('wheel');
      if (wheel) {
        this.form.wheel = JSON.parse(wheel);
      }
      let mass = localStorage.getItem('mass');
      if (mass) {
        this.form.mass = JSON.parse(mass);
      }
      let stepFrequency = localStorage.getItem('stepFrequency');
      if (stepFrequency) {
        this.form.stepFrequency = JSON.parse(stepFrequency);
      }
      let mu = localStorage.getItem('mu');
      if (mu) {
        this.form.mu = JSON.parse(mu);
      }
      let ec = localStorage.getItem('ec');
      if (ec) {
        this.form.ec = JSON.parse(ec);
      }
      let rho = localStorage.getItem('rho');
      if (rho) {
        this.form.rho = JSON.parse(rho);
      }
      let g = localStorage.getItem('g');
      if (g) {
        this.form.g = JSON.parse(g);
      }
      let factor = localStorage.getItem('factor');
      if (factor) {
        this.form.factor = JSON.parse(factor);
      }
      let myCda = localStorage.getItem('myCda');
      if (myCda) {
        this.form.myCda = JSON.parse(myCda);
      }
      let myRoad = localStorage.getItem('myRoad');
      if (myRoad) {
        this.form.myRoad = JSON.parse(myRoad);
      }


    }
  },
  mounted() {

    // 基于准备好的dom，初始化echarts实例
    this.ratioChart = this.$echarts.init(document.querySelector('#ratio-chart'))
    this.RPMUChart = this.$echarts.init(document.querySelector('#rpm-u-chart'))
    this.PSChart = this.$echarts.init(document.querySelector('#p-s-chart'))
    this.PUChart = this.$echarts.init(document.querySelector('#p-u-chart'))

    this.initLayout();


    this.parseMd()

    // 取出数据
    this.retrieveFromBrowserStorage();

    // setTimeout(() => {
    //   this.loading = true;
    //
    //   setTimeout(() => {
    //     this.loading = false;
    //   }, 3000);
    // }, 1000);

  },


}

</script>


<style>
html,
body {
  margin: 0;
  padding: 0;
}

.el-header {
  background: linear-gradient(-20deg, #e73c7e, #23a6d5, #23d5ab);
  background-size: 300% 300%;
  animation: Gradient 40s ease infinite;
  color: #333;
  text-align: center;
  line-height: 50px;
  user-select: none;
  height: 50px !important;
}

@keyframes Gradient {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0 0;
  }
}

#main {
  background-color: rgba(239, 239, 239, 0);
  user-select: none;
}


.title,
.title2,
.el-container .el-drawer__header {
  color: rgb(102, 177, 255);
}

.title2 {
  text-align: center;
}

.input-div {
  background: #ffffff;
  color: rgb(87, 87, 87);
  font-weight: 500;
  /* height: 500; */
  text-align: center;
}

#formContainer {
  margin-top: 20px;
  width: 90%;
  display: inline-block; /* 确保内容在一行内 */
}


.grid-content {
  border-radius: 10px;
  min-height: 690px;
  box-shadow: 2px 2px 15px -2px rgba(0, 0, 0, .3);
  padding: 20px 15px 15px 15px;
}


.el-select,
.el-input {
  flex: 1;
}

.el-input .el-input__inner {
  text-align: center;
}


/* 对选中项的文本进行居中 */
.el-select .el-input .el-input__inner {
  text-align: center;
}

/* 对待选项的文本进行居中 */
.el-select-dropdown .el-scrollbar .el-select-dropdown__wrap .el-scrollbar__view .el-select-dropdown__item {
  text-align: center !important;
}


.chart-container {
  width: 100%;
  /* height: 380.75px; */
}

.text-div {
  background-color: #ffffff;
  font-size: 14px;
  color: #8a8a8a;
}

.chart-container .el-descriptions__title {
  font-size: 14px;
  color: rgb(102, 177, 255);
  font-weight: 300;
  font-family: 'sans-serif';
}

.chart-container .el-descriptions__header {
  margin-bottom: 3px;
}

.el-icon-fork-spoon,
.el-icon-full-screen,
.el-icon-menu {
  font-size: 20px;
  /* 初始字体大小 */
  color: white;
  transition: font-size 0.2s;
  /* 过渡效果，使字体大小变化平滑 */
}

.el-icon-fork-spoon:hover,
.el-icon-full-screen:hover,
.el-icon-menu:hover {
  font-size: 24px;
  /* 鼠标悬浮时的字体大小 */
}

/*提示框的样式*/
.tps.el-tooltip__popper.is-light {
  border-color: #dee2e6;
  color: #9d9d9d
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow:after,
.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-color: #dee2e6;
  opacity: 0;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow:after,
.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-color: #dee2e6;
  opacity: 0;
}


/* 提示框的自动换行 */
.tooltip-content {
  word-wrap: break-word;
  /* 兼容旧版本浏览器 */
  overflow-wrap: break-word;
  /* 兼容新版本浏览器 */
  max-width: 240px;
}

.item.set-other-btn {
  color: #dee2e6;
  background-color: #fff;
  border-color: #dee2e6;
}

.item.set-other-btn:hover {
  color: #adadad;
  background-color: #fff;
  border-color: #adadad;
}

.item.set-other-btn:focus {
  color: #adadad;
  background-color: #fff;
  border-color: #adadad;
}

.item.set-other-btn:active {
  color: #dee2e6;
  background-color: #fff;
  border-color: #dee2e6;
}
</style>
